import '../../styles/pages/fall-winter-2021/leaving-a-mark-in-the-marvel-universe.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import YouTube from 'react-youtube';
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import { Preloader, Placeholder } from 'react-preloading-screen';
import { WhisperSpinner } from "react-spinners-kit";


import socialBanner from "../../images/fall-winter-2021/social-banners/leaving-a-mark-in-the-marvel-universe.jpg";

import heroBG from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/hero-bg.jpg';
import title from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/title-graphic-b.svg';

import crosbyChild from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/brian-crosby-child-1.jpg';
import crosbyStanLee from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/brian-crosby-and-stan-lee.jpg';

// import missionBreakout_2 from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/mission-breakout-2.jpg';
// import missionBreakout_1 from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/mission-breakout-1.jpg';
// import ironManExperience_2 from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/iron-man-experience-2.jpg';
// import ironManExperience_1 from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/iron-man-experience-1.jpg';

import missionBreakout from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/mission-breakout.jpg';
import ironManExperience from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/iron-man-experience.jpg';

import crosbyIllustration from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/crosby-illustration.png';
import cityScape from '../../images/fall-winter-2021/leaving-a-mark-in-the-marvel-universe/city-scape.svg';



export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Leaving a Mark in the Marvel Universe';
    var pageDesc = 'An illustration alumnus lands his dream job with Marvel Themed Entertainment.';
    var slug = 'leaving-a-mark-in-the-marvel-universe';


    var heroStyles = {
      backgroundColor: '#fe0000',
      backgroundImage: `url(${heroBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

          <div className="hero animated fadeIn" style={heroStyles}>
            <div className="text">
              <h1 className="animated zoomIn delay-1s"><img src={title} alt={pageTitle} id="title-graphic" /></h1>
              <p className="sub-heading animated fadeInDown delay-1s">An illustration alumnus lands his dream job with Marvel Themed Entertainment.</p>
              <span className="animated fadeInDown delay-1s byline">By Lynn Juliano, Photos courtesy of Brian Crosby</span>
            </div>
            <img src={crosbyIllustration} alt="" className="animated slideInUp delay-1s" id="crosby-illustration" />
            <img src={cityScape} alt="" className="animated slideInUp delay-1s fast" id="city-scape" />
          </div>
          <div className="story-wrap">
            <div className="wrap small">

              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p><span className="intro-text"><span className="drop-cap">B</span>rian Crosby always gravitated toward</span><br />gritty, street-level comic book characters, such as the Punisher, Daredevil and Moon Knight. “Maybe I thought if I worked really hard, I could become one,” he muses.</p>
        
                <p>In the meantime, the Cal State Fullerton alumnus and self-described “comic book nerd” is working another one of his dream jobs as creative director of Marvel Themed Entertainment, a subsidiary of The Walt Disney Co.</p>
  
                <p>Crosby and his team help bring to dimensional life the 9,000+ character library of Marvel Super Heroes through theme park attractions, museum exhibits, character appearances, traveling experiences, immersive walkthroughs and pop culture comic conventions.</p>
      
                <p>Before this role, Crosby spent a decade as a concept designer for Walt Disney Imagineering, a job he landed as an art student at Cal State Fullerton.</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2>Turning a Dream Into Reality</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                <figure className="align-right align-outside large">
                  <img src={crosbyChild} alt="" />
                  <figcaption>Brian “Batman” Crosby at age 7 and his brother, Bobby</figcaption>
                </figure>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p>When Crosby was a child, he was “really into superheroes.” One day his mom took him to a 7-Eleven, where he selected his first comic book from a spinner rack: “Detective Comics, No. 577.”</p>
      
                <p>“When I got home, I opened up the book and immediately saw the credits. For the first time, it dawned on me that there were people who did this for a living,” recalls Crosby. “At 10 years old, I knew I needed to be one of these guys who draws superheroes for a living.”</p>
  
                <p>More than a decade later, he enrolled in art school at Cal State Fullerton. Crosby says the illustration program lived up to its strong reputation, led by supportive faculty mentors with industry expertise.</p>
  
                <p>While a student, he learned about a Disney design competition called ImagiNations. The competition aims to find diverse talent for Walt Disney Imagineering, which designs global theme park attractions. </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                <blockquote>
                  <p>"Coming to Cal State Fullerton was a great decision for me. I respected the professors who were actively working in the industry and doing the things that I wanted to do."</p>
                  <cite>- Brian Crosby '07, creative director of Marvel Themed Entertainment</cite>
                </blockquote>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p>He and his friend, Josh Steadman, entered the competition with a project based on the 1991 Disney film “The Rocketeer.” While they didn’t win the competition, they caught the attention of Imagineering and Crosby earned a summer internship. </p>
                
                <p>Finishing up his Bachelor of Fine Arts in art-illustration in 2007, Crosby parlayed his internship into a full-time role in Walt Disney Imagineering’s Visual Imaging Production department.</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2>Making the Leap From Disney to Marvel</h2>
                <p>From assisting Disney creatives with their presentations to becoming a concept designer himself, Crosby worked on a variety of projects throughout the Disney parks, including “Finding Nemo Submarine Voyage” in Anaheim, “it's a small world” in Hong Kong, “Tron Lightcycle Power Run” in Shanghai, “Star Wars” and “Avatar.”</p>
      
                <p>He co-created the Disney Kingdoms line of comic books in partnership with Marvel Comics,  designed to tell new stories and expand upon lands, attractions, characters, and the worlds of Walt Disney Parks and Resorts.</p>
  
                <p>Staying late almost every night to work on presentations and ideas, Crosby believes there’s no substitute for three things: hard work, sacrifice and experience.</p>
  
                <p>“Nobody’s going to show up at your doorstep with your dream job — at least in my experience,” he says. “I spent a lot of late nights working on my portfolio, developing my craft, meeting people and networking.”</p>
  
                <p>In 2009, Marvel became part of the Disney family and “a light bulb went off” for Crosby. Knowing the brand inside and out, Crosby signed up to work on everything and anything Marvel-related.</p>
  
                <p>“I thought, ‘Finally! I’m going to get to work on Super Hero projects in the Disney parks — it’s the perfect blend of everything I love.’”</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2>Building on a Design Legacy</h2>
                <p>One of Crosby’s first Marvel projects was an Avengers wrap of the Monorail at Walt Disney World Resort. “Iron Man Experience” at Hong Kong Disneyland Resort and “Guardians of the Galaxy – Mission: Breakout!” at Disney California Adventure soon followed.</p>

              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p>“Iron Man Experience,” the first Marvel-themed attraction at any Disney park, is one of the most popular attractions at Hong Kong Disneyland to date.</p>
  
                <p>The night before a pitch meeting with Disney executives, Crosby came up with a “dark horse” idea for a motion simulator attraction akin to “Star Tours.” He spent his train ride to work sketching out a rough concept.</p>

              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <figure className="align-wide align-wide-large no-border">
                  <img src={ironManExperience} alt="Collage of “Iron Man Experience” at Hong Kong Disneyland Resort" />
                  <figcaption>“Iron Man Experience” at Hong Kong Disneyland Resort</figcaption>
                </figure>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>

                <p>After his team shared several other ideas, met with lukewarm response, Crosby was asked to present one last concept.</p>
  
                <p>“Iron Man is one of the most popular characters in Hong Kong,” he explains. “We brought out a very rudimentary pitch board that I’d put together and shared the simulator idea. Sure enough, they loved it.”</p>
  
                <p>“Guardians of the Galaxy – Mission: Breakout!” proved a different kind of challenge. Reinventing a beloved attraction, “The Twilight Zone Tower of Terror,” wasn’t popular with everyone.</p>
      
                <p>“We took that as a challenge to try to deliver something even better,” says Crosby. “We knew we needed to do something radically different with the ride: ‘Tower of Terror’ was about suspense and mystery, while the ‘Guardians of the Galaxy’ franchise is irreverent and funny.</p>
              </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <figure className="no-border">
                  <img src={missionBreakout} alt="Collage of photos from Guardians of the Galaxy – Mission: Breakout!" style={{maxWidth: '99%', margin: '.5em auto 1.5em auto', display: 'block'}} />
                  <figcaption style={{right: 0}}>“Guardians of the Galaxy – Mission: Breakout!” at Disney California Adventure</figcaption>
                </figure>
                </ScrollAnimation>
                <div class="wrap small">
  
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p>What resulted was a colorful and humorous ride, where the Guardians are held captive and Rocket needs the audience’s help to liberate them. </p>
  
                <p>“When I see people post pictures on social media, riding the ride and having fun, it's pretty gratifying,” he says. “The ride is a family favorite at the Crosby house, and now it’s part of the new Avengers Campus.”</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                <figure className="align-outside large align-left">
                  <img src={crosbyStanLee} alt="Brian Crosby and Stan Lee" />
                  <figcaption>Brian Crosby and Stan Lee, the late executive vice president and publisher of Marvel Comics</figcaption>
                </figure>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <p>Reflecting on his work, Crosby is proud to share in the legacy of designing experiences for families to enjoy for generations.</p>
  
                <p>“We stand on the shoulders of giants, and I recognize that there were brilliant creators who came before us,” expresses Crosby. “And now, to be a part of the Marvel Universe is pretty awesome. I’m humbled and grateful for it all, really.” <span className="end-mark" /></p>
                </ScrollAnimation>

                <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="frame support-frame">

              <p>Support the<br />College of the Arts:<br /><a href="https://campaign.fullerton.edu/arts" target="_blank">campaign.fullerton.edu/arts</a></p>

            </ScrollAnimation>
  
            </div>
          </div>
          <div className="frame-flex" id="marvel-draw">
            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="frame red-bg">

              <h2>Marvel Draw!</h2>
              <p>During the COVID-19 pandemic and shelter in place orders, Brian Crosby ’07 (BFA, art-illustration) and his Marvel team launched a how-to-draw series. <a href="https://www.youtube.com/playlist?list=PLNw_hEO5pK1qZr8pVt-1clkHOf2f2EPeK" target="blank" rel="noreferrer">“Marvel Draw!”</a> teaches children and adults to draw characters from Spider-Man and Baby Groot to Captain America and Captain Marvel.</p>

            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="frame gray-bg marvel-draw-video">

              <YouTube
                containerClassName="embed-container video"
                videoId="w47MtwWM1To"
                opts={{
                  height: '478',
                  width: '850',
                  playerVars: {
                    rel: 0
                  },
                }}
              />

            </ScrollAnimation>
          </div>
          
          <MoreStories slug={slug} />

          <Placeholder>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <WhisperSpinner size="30" frontColor="#e47d80" backColor="#ad3539" />
              <span style={{
                fontSize: '.7em',
                display: 'block', 
                marginTop: '30px', 
                color: '#ccc', 
                textTransform: 'uppercase',
                textAlign: 'center',
                letterSpacing: '5px'
              }}>
                Loading
              </span>
            </div>
          </Placeholder>
        </Preloader>
      </Layout>
    );
  }
} 